import { usePremCalcStore } from "@premcalc/store/premCalcStore";
import { useApi } from "@util/api-service";

export const usePremCalcService = () => {
  const currentLanguage = document.getElementById("currentLanguage") as HTMLInputElement;
  const currentBlock = document.getElementById("currentPage") as HTMLInputElement;
  const store = usePremCalcStore();
  store.errorMessage = '';

  const getViewmodel = async () => {
    store.errorMessage = '';
    const api = useApi('/PremiumCalculatorApi/GetViewModel');
    const request = {
      Lang: currentLanguage.value,
      CurrentBlock: currentBlock.value
    };

    api.post<string>(request)
      .then((data: any) => {
        const json = JSON.parse(data);
        store.countries = json.countries;
        store.translations = json.translations;
      })
      .catch((error) => {
        store.errorMessage = error.message;
      });

    store.currency = [
      { value: "0", name: "SEK" },
      { value: "1", name: "USD" },
      { value: "2", name: "EUR" },
      { value: "3", name: "CHF" },
      { value: "4", name: "JPY" },
    ]
    store.currentCurrency = store.currency[0].value;
  };

  const getCountryInfo = async (country: number | null) => {
    store.errorMessage = '';
    const api = useApi('/PremiumCalculatorApi/GetCountryInfo');
    const request = {
      lang: currentLanguage.value,
      country: country,
      currentBlock: currentBlock.value
    };

    api.post<string>(request)
      .then((data) => {
        const json = JSON.parse(data) as CountryInfo;
        store.currentCountryInfo = json;
      })
      .catch((error) => {
        store.errorMessage = error.message;
      });
  }

  const shortCalculation = async () => {
    store.errorMessage = '';
    const api = useApi('/PremiumCalculatorApi/ShortCalculation');

    const request: ShortCalculationRequest = {
      months: store.creditPeriod,
      page: store.currentCountry,
      lang: currentLanguage.value,
      guarantee: store.politicalEvents?.value ?? '0',
      guaranteeK: store.commercialEvents?.value ?? '0',
    }

    api.post<string>(request)
      .then((data) => {
        const json = JSON.parse(data) as ShortCalculationResponse;
        store.shortCalculation = json;
      })
      .then(() => {
        scrollTo('js-shortCalculation');
      })
      .catch((error) => {
        if (error.status === 418) {
          store.noCalculation = true;
        }
        store.errorMessage = error.message;
      });
  };
  const longCalculation = async () => {
    store.errorMessage = '';
    const api = useApi('/PremiumCalculatorApi/LongCalculation');

    const request: LongCalculationRequest = {
      amount: store.amount.toString(),
      currency: store.currentCurrency,
      delivery: store.deliveryTime.toString(),
      interest: store.interestRate.toString(),
      months: store.creditPeriod.toString(),
      page: store.currentCountry,
      lang: currentLanguage.value
    }

    api.post<string>(request)
      .then((data) => {
        const json = JSON.parse(data) as LongCalculationResponse;
        store.longCalculation = json;
      })
      .then(() => {
        scrollTo('js-longCalculation');
      })
      .catch((error) => {
        if (error.status === 418) {
          store.noCalculation = true;
        }
        store.errorMessage = error.message;
      });
  };

  const scrollTo = (id: string) => {
    let element = document.getElementById(id);
    let header = document.getElementsByClassName('header')[0] as HTMLElement;

    if (!element) {
      return;
    }
    window.scrollTo({ top: element.offsetTop - header?.offsetHeight, behavior: 'smooth' });
  };

  return {
    getViewmodel, getCountryInfo, shortCalculation, longCalculation
  };
};