import { createPinia } from "pinia";

import { createApp } from 'vue';
import App from './App/App.vue';

const pinia = createPinia()

const app = createApp(App);

app.use(pinia);
app.mount("#js-premiumCalculatorApp");