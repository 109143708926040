import { defineStore } from "pinia";

export const usePremCalcStore = defineStore('premCalcStore', {
  state: (): PremiumCalculator.State => {
    return {
      formInvalid: false,
      errorMessage: '',
      noCalculation: false,
      showModal: false,
      countries: [],
      creditPeriod: 0,
      amount: 0,
      deliveryTime: 0,
      interestRate: 0,
      currency: [],
      currentCountry: null,
      currentCurrency: '',
      commercialEvents: { name: '', value: '100' },
      politicalEvents: { name: '', value: '100' },
      translations: {},
      currentCountryInfo: null,
      shortCalculation: null,
      longCalculation: null,
    };
  },

  actions: {
    onShowModal() {
      this.showModal = true;
    },
    onCloseModal() {
      this.showModal = false;
    }
  },
})