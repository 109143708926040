import { ref } from 'vue';

export const useApi = (url: string) => {
  const baseURL = "/api";
  const loading = ref(false);
  let baseConfig = {
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json" },
  };

  const get = async <T>(query?: Record<string, any>, config?: {}): Promise<T> => {
    loading.value = true;
    let queryString = '';

    const mergedConfig = {...baseConfig, ...config, "method": "GET" };

    if (query) {
      queryString = '?' + Object.entries(query)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join('&');
    }

    return fetch(`${baseURL}${url}${queryString}`, mergedConfig)
      .then(response => {
        if (!response.ok) {
          throwError(response);
        }
        return response.json();
      })
      .catch(handleError<T>)
      .finally(() => loading.value = false);
  }

  const post = async <T>(data: {}, config?: {}): Promise<T> => {
    loading.value = true;

    const mergedConfig = {...baseConfig, ...config, method: "POST", body: JSON.stringify(data) };

    return fetch(`${baseURL}${url}`, mergedConfig)
      .then(response => {
        if (!response.ok) {
          throwError(response);
        }
        return response.json();
      })
      .catch(handleError<T>)
      .finally(() => loading.value = false);
  }

  const put = async <T>(data?: {}, config?: {}): Promise<T> => {
    // const mergedConfig = {...baseConfig, ...config, method: "PUT", body: JSON.stringify(data) };
    //
    // const response = await fetch(`${baseURL} + ${url}`, mergedConfig)
    //   .then(response => response as T)
    //   .catch(handleError<T>)
    //   .finally(() => loading.value = false);
    //
    // return response.json()
  }

  const remove = async <T>(config?: {}): Promise<T> => {
    // const mergedConfig = {...baseConfig, ...config, method: "DELETE" };
    //
    // const response = await etch(`${baseURL} + ${url}`, mergedConfig)
    //   .then(response => response as T)
    //   .catch(handleError<T>)
    //   .finally(() => loading.value = false);
    //
    // return response.json()
  }

  const handleError = <T>(error: any): Promise<T> => {
    if (error.code === 'ERR_NETWORK') {
      location.reload();
    }

    return Promise.reject(error?.response?.data || error.message || error);
  }

  const throwError = (response: Response) => {
    const error = new Error(response.statusText) as any;
    error.status = response.status;
    throw error;
  };

  return {
    get,
    post,
    put,
    remove,
    loading
  }
}